import React from "react"
import Homepage from "./Homepage"
import Layout from "../components/layout/Layout"
import Seo from "../components/seo"
import featuredImage from '../static/undraw_dark_analytics_7axy.png'

const IndexPage = () => (
  <Layout>
    <Seo title="School of Content - Content Strategy, Management, and Marketing" description="We're a content marketing 
    agency that focuses on content strategy, management, and marketing for SaaS and e-commerce companies. We 
    share our knowledge through clearly-written, hands-on articles, and offer practical advice."
    image={featuredImage}
    featuredImage={featuredImage} 
    />
    <Homepage />
  </Layout>
)

export default IndexPage
